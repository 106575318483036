/* print styles */

@import "base/settings";
@import "base/bootstrap/_variables.scss";
@import "base/mixins.scss";
@import "utils/functions";
@import "base/fonts.scss";

// mdi
@import "../node_modules/mdi/scss/materialdesignicons.scss";

// glyph
@import "../bower_components/bootstrap/scss/_glyphicons.scss";

// OSM
@import "../node_modules/ol/ol.css";

// vue-css-donut-chart
@import "../node_modules/vue-css-donut-chart/dist/vcdonut.css";

// old
body.print { display: block; text-align: center; font-family: 'Roboto', sans-serif; font-size: 11px; }

#printcontent { width: 100%; max-width: 950px; margin-left: auto; margin-right: auto; text-align: left; padding-top: 1em; }
table.pheader { width: 100%; }
#pfooter { width: 100%; text-align: right; }

h1, h2, h3, h4 { font-weight: normal; font-family: 'Arial', sans-serif; }
h1 { font-size: 16px; }
h1.title { margin-top: 12px; margin-bottom: 8px; }
h2 { font-size: 14px; }
h3 { font-size: 12px; text-decoration: underline; }
h4 { font-size: 12px; font-weight: bold; }

table.receipt { width: 100%; border-spacing: 0; border-collapse: collapse; margin-top: 18px; margin-bottom: 18px; font-family: 'Roboto', Helvetica, sans-serif; }
table.receipt th { border: 1px solid black; padding: 6px; background-color: #bbbbbb !important;  color: black; border-bottom: 0;  width: 25%; }
table.receipt td { border: 1px solid black; padding: 6px; text-align: center; border-top: 0px; width: 25%; }

table.receipt .dark { background-color: #000000; color: #ffffff; } 
.snipsnap { width: 100%; border: 0; }
.ads_banner { width: 100%; border: 0; }
td.empty { height: 85px; min-height: 85px; }

.byhand { height: 85px; min-height: 85px; }

.warn { color: #f00000; text-align: center; font-weight: bold; }

// end old
